import React from 'react';
import Media from 'react-media';
// @ts-ignore
import Ecosphere from '../components/ecosphere';
import WrapperLayout, { LayoutProps } from '../components/layout';

const EcospherePage: React.FC<LayoutProps> = (props) => {
  const isNode = typeof window === 'undefined';

  return (
    <WrapperLayout {...props}>
      <Media query="(max-width: 599px)">
          {(isMobile) => <Ecosphere {...props} isMobile={isMobile && !isNode} />}
      </Media>
    </WrapperLayout>
  );
};

export default EcospherePage;
